.footer-button p {
    transition: color 0.2s;
}

.footer-button:hover p {
    color: rgba(2, 135, 194, 1);
}

.footer-icon-link:hover .iconify {
    color: #fcba03;
}